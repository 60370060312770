import styled from "styled-components"

export const CTAButton = styled.a`
    border-radius: 5px;
    font-size: 24px;
    width: 243px;
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    font-weight: 400;
    color: ${p => p.theme.colors.white};
    background-color:rgba(0,0,0,0);
    cursor: pointer;
    border: 1px solid ${p=>p.theme.colors.gold};
    transition: background-color 0.4s ease-in;
    &:hover {
        background-color: ${p=>p.theme.colors.gold};
    }
`

export const FormButton = styled.button`
    border-radius: 5px;
    font-size: 24px;
    width: 200px;
    height: 46px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${p => p.theme.colors.white};
    background-color:rgba(0,0,0,0);
    cursor: pointer;
    border: 1px solid ${p=>p.theme.colors.gold};
    transition: background-color 0.4s ease-in;
    &:hover {
        background-color: ${p=>p.theme.colors.gold};
    }
    &.inactive {
        background-color: ${p=>p.theme.colors.gray};
        color: ${p=>p.theme.colors.primary};
    }
`