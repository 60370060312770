import React, { useState, useRef } from "react"
import * as yup from "yup"
import { useFormik } from "formik"
import axios from "axios"
import {
    StyledInput,
    StyledTextarea,
    HiddenInput,
    Wrapper,
    Form as StyledForm,
    InputsWrapper,
    InputWrapper,
    ErrorContainer,
    HeadingWrapper
} from "./styles"
import { Subheading, FormLabel, FormLabelSpan, FormError } from "components/Typography"
import { Checkbox } from "./Checkbox"
import { SubmitMessage } from "./SubmitMessage"
import { SectionWrapper } from "shared/Styles/Wrappers"

import { scrollToElement } from "./scrollToElement"

import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import { FormButton } from "components/Buttons"


const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
yup.addMethod(yup.string, "phone", function () {
    return this.test("phone", "Podaj poprawny numer telefonu", value =>
        rePhoneNumber.test(value)
    )
})

const validationSchema = yup.object().shape({
    name: yup.string().required("Pole wymagane"),
    phone_number: yup.string().phone().required("Pole wymagane").min(1, "Pole wymagane"),
    content: yup.string().required("Pole wymagane").min(1, "Pole wymagane"),
    email: yup
        .string()
        .email("Nieprawidłowy adres email"),
    rodo_check: yup.bool().oneOf([true], "Pole wymagane"),
    // recaptcha: yup.string().required("Pole wymagane"),
})

const Form = () => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const formRef = useRef(null)
    const { executeRecaptcha } = useGoogleReCaptcha()
    const {
        handleSubmit,
        handleChange,
        values,
        errors,
        touched,
        validateOnChange,
        setFieldTouched,
        handleBlur,
        submitForm,
        resetForm,
        setFieldValue,
    } = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone_number: "",
            content: "",
            rodo_check: false,
            recaptcha: "",
        },
        validateOnChange: false,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            const token = await executeRecaptcha('submitform');
            const data = { ...values, 'g-recaptcha-response': token }
            axios.post('https://adwokatsebastianczyz.pythonanywhere.com/contact', data).then(res => { setIsSubmitting(true); resetForm(); }).catch(err => {
                setIsSubmitting(false)
            })
        }
    })


    return (
        <SectionWrapper id="kontakt">
            <Wrapper ref={formRef}>
                <StyledForm submitted={isSubmitting} onSubmit={handleSubmit}>
                    <HeadingWrapper>
                        {/* <Subheading>Kontakt</Subheading> */}
                    </HeadingWrapper>
                    <InputsWrapper>
                        <InputWrapper>
                            <FormLabel className={touched.name && errors.name ? 'error' : ''} htmlFor="name">Imię i Nazwisko</FormLabel>
                            <StyledInput
                                name={"name"}
                                className={touched.name && errors.name ? 'error' : ''}
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            // placeholder="Imię i Nazwisko"
                            />
                            <ErrorContainer>
                                {touched.name && errors.name ? <FormError>{errors.name}</FormError> : null}
                            </ErrorContainer>
                        </InputWrapper>
                        <InputWrapper>
                            <FormLabel className={touched.email && errors.email ? 'error' : ''} htmlFor="name">Adres e-mail <FormLabelSpan>(opcjonalne)</FormLabelSpan></FormLabel>
                            <StyledInput
                                name={"email"}
                                className={touched.email && errors.email ? 'error' : ''}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}

                            // placeholder="Email"
                            />
                            <ErrorContainer>
                                {touched.email && errors.email ? <FormError>{errors.email}</FormError> : null}
                            </ErrorContainer>
                        </InputWrapper>
                        <InputWrapper>
                            <FormLabel className={touched.phone_number && errors.phone_number ? 'error' : ''} htmlFor="phone_number">Numer telefonu</FormLabel>

                            <StyledInput
                                name={"phone_number"}
                                className={touched.phone_number && errors.phone_number ? 'error' : ''}
                                value={values.phone_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            // placeholder="Telefon"
                            />
                            <ErrorContainer>
                                {touched.phone_number && errors.phone_number ? <FormError>{errors.phone_number}</FormError> : null}
                            </ErrorContainer>
                        </InputWrapper>
                        <InputWrapper className="h-170">
                            <FormLabel className={touched.content && errors.content ? 'error' : ''} htmlFor="content">Wiadomość</FormLabel>
                            <StyledTextarea
                                name={"content"}
                                className={touched.content && errors.content ? 'error' : ''}
                                value={values.content}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            // placeholder="Wiadomość"
                            ></StyledTextarea>
                            <ErrorContainer>
                                {touched.content && errors.content ? <FormError>{errors.content}</FormError> : null}
                            </ErrorContainer>
                        </InputWrapper>
                    </InputsWrapper>
                    <InputWrapper>
                        <HiddenInput
                            name={"rodo_check"}
                            value={values.rodo_check}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="checkbox"
                        />
                        <Checkbox
                            value={values.rodo_check}
                            handleClick={value => { setFieldValue("rodo_check", value).then(() => setFieldTouched('rodo_check', true)) }}
                        >
                            Zgodnie z art. 6 ust. 1 lit. f RODO informujemy, iż Pani/Pana dane osobowe, przekazane nam za pośrednictwem naszej strony internetowej/formularza kontaktowego lub w inny sposób (przekazanie wizytówki, przesłanie poczty elektronicznej, kontakt telefoniczny), są gromadzone, przetwarzane i przechowywane w celu prowadzenia korespondencji z Państwem i w celu, dla którego zostały nam udostępnione. Posiada Pani/Pan prawo dostępu do treści swoich danych, prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych lub do wniesienia sprzeciwu wobec ich przetwarzania. Podanie danych jest dobrowolne, ale niezbędne do realizacji powyższych celów. Pani/Pana dane będą przetwarzane do czasu ustania celu dla jakiego zostały zgromadzone.
                            Administrator Danych Osobowych: Kancelaria Adwokacka
                            adw. Sebastian Czyż, Siedliska Kolonia 32, 22-400 Zamość
                        </Checkbox>
                        <ErrorContainer>
                            {touched.rodo_check && errors.rodo_check ? <FormError>{errors.rodo_check}</FormError> : null}
                        </ErrorContainer>
                    </InputWrapper>
                    <InputWrapper>
                        {/* <ErrorContainer>
                            {errors.recaptcha && touched.recaptcha && (
                                <p>{errors.recaptcha}</p>
                            )}
                        </ErrorContainer> */}
                    </InputWrapper>
                    <InputWrapper>
                        <FormButton
                            onClick={e => {
                                e.preventDefault()
                                scrollToElement(formRef, -150)
                                submitForm()

                            }}
                            className={isSubmitting ? "inactive" : ""}
                            type="submit"
                        >
                            Wyślij
                        </FormButton>
                    </InputWrapper>
                </StyledForm>
                <SubmitMessage submitted={isSubmitting} />
            </Wrapper>
        </SectionWrapper>
    )
}

export { Form }
