import * as React from "react"
import { Logo } from "components/Logo"
import { Heading, HeadingSpan, Paragraph, Link } from "components/Typography"
import { CTAButton } from "components/Buttons"
import { MailIcon, PhoneIcon, MarkerIcon } from "components/Icons"
import { Form } from "components/Form"
import styled from "styled-components"

import SEO from "../seo"

const MapWrapper = styled.div`
 overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
    iframe {
      left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
 
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints.tablet_small}) {
      padding-bottom:30.5%;
    }
`

const HeroContainer = styled.section`
  width: 100%;
  height: calc(100vh - 10px);
position: relative;
@media screen and (min-width: ${(props) => props.theme.breakpoints.tablet_small}) {
    padding: 0;
    height: calc(100vh - 40px);
  
  }
`

const LogoWrapper = styled.div`
  margin-bottom: 24px;
  svg {
    width: 100px;
    height: auto;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.tablet_small}) {
    margin-bottom: 24px;
    svg {
      width: 100px;
      height: unset;
    }
  }
`

const HeadingWrapper = styled.div`
margin-bottom: 24px;
@media screen and (min-width: ${(props) => props.theme.breakpoints.tablet_small}) {
  margin-bottom: 24px;

}
`

const InfoWrapper = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
display: flex;
flex-direction: column;
align-items: center;
text-align:center;
`

const ContactInfoWrapper = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 24px;
@media screen and (min-width: ${(props) => props.theme.breakpoints.tablet_small}) {
  margin-bottom: 24px;

}
`

const ContactItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`

const IconWrapper = styled.div`
  margin-right: 8px;
  svg {
    height: 32px;
    width: auto;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.tablet_small}) {
 svg {
   width: unset;
   height: unset;
 }

}
`

const Index = () => {
  return (
    <>
      <SEO description="Kancelaria Adwokacka Adwokat Sebastian Czyż" title="Adwokat Sebastian Czyż - kontakt" />
      <HeroContainer>
        <InfoWrapper>
          <LogoWrapper>
            {/* <Logo /> */}
          </LogoWrapper>
          <HeadingWrapper>
            <Heading><HeadingSpan>Kancelaria Adwokacka</HeadingSpan><br></br>Adwokat Sebastian Czyż</Heading>
          </HeadingWrapper>
          <ContactInfoWrapper>
            <ContactItem>
              <IconWrapper>
                <PhoneIcon />
              </IconWrapper>
              <Link href="tel:668088947">668 088 947</Link>
            </ContactItem>
            <ContactItem>
              <IconWrapper>
                <MailIcon />
              </IconWrapper>
              <Link href="mailto:czyz.sebastian@outlook.com">czyz.sebastian@outlook.com</Link>
            </ContactItem>
            <ContactItem>
              <IconWrapper>
                <MarkerIcon />
              </IconWrapper>
              <Link href="https://goo.gl/maps/kP64mgzwzunTXNtbA"><Paragraph>Siedliska Kolonia 32</Paragraph><Paragraph>22-400 Zamość</Paragraph></Link>
            </ContactItem>
          </ContactInfoWrapper>
          <CTAButton href="#kontakt">
            Kontakt
          </CTAButton>
        </InfoWrapper>
      </HeroContainer>
      <Form />
      {/* <Link href="https://goo.gl/maps/kP64mgzwzunTXNtbA">
      <MapContainer />
      </Link> */}
      <MapWrapper>
        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2525.5656961372315!2d23.128838315626606!3d50.72799597951453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472369cdaea78521%3A0x1e0805022b874a81!2sSiedliska-Kolonia%2032%2C%2022-400!5e0!3m2!1spl!2spl!4v1632430440126!5m2!1spl!2spl" width="600" height="450" style={{border: 0}} allowFullScreen="" loading="lazy"></iframe>
      </MapWrapper>
    </>
  )
}


export default Index
