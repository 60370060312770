import React from "react"
import { SubmitMessageContainer, SubmitCheckmark, Message } from "./styles"

export const SubmitMessage = ({submitted}) => {
    return (
        <SubmitMessageContainer submitted={submitted}>
        <SubmitCheckmark
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130.2 130.2"
        >
          <circle
            className="path circle"
            fill="none"
            stroke="#A88622"
            strokeWidth="3"
            strokeMiterlimit="10"
            cx="65.1"
            cy="65.1"
            r="62.1"
          />
          <polyline
            className="path check"
            fill="none"
            stroke="#A88622"
            strokeWidth="5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            points="100.2,40.2 51.5,88.8 29.8,67.5 "
          />
        </SubmitCheckmark>
        <Message>Dziękujemy za przesłanie formularza</Message>
      </SubmitMessageContainer>
    )
}
